.Registration {
  background: #f6f7fa;

  &__section {
    overflow: hidden;

    @media only screen and (min-width: 768px) {
      padding: 40px 0;
    }
  }
}
