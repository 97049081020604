.help.is-danger {
  font-size: 14px;
  color: #f9587a;
  margin-top: 8px;
}

ul.help.is-danger {
  padding-left: 30px;
  list-style: initial;
}
