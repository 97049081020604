.Input {
  transition: border 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 16px;
  border-radius: 4px;
  padding: 12px 16px;
  background: #f8f9f9;
  color: #8fb0d4;
  outline: none;
  width: 100%;
  box-shadow: none;

  &:focus,
  &:active {
    border: 1px solid #00beff;
    box-shadow: 0 0 0 1px #009feb;
  }

  &--default {
    background: #fff;
    color: #1e3f6b;
    border: solid 1px #8fb0d4;

    &:focus,
    &:active {
      border: 1px solid #00beff;
      box-shadow: 0 0 0 1px #009feb;
    }
  }

  &--disabled {
    border: solid 1px #bed2e8;
    background: #f8f9f9;
    color: #1e3f6b;

    &::placeholder {
      color: #8fb0d4;
    }

    &:focus {
      background: #fff;
      border: solid 1px #1fc4ff;
      box-shadow: 0 0 0 1px #1fc4ff;
    }

    &:hover {
      border-color: #1fc4ff;
    }
  }

  &--info {
    background: #fff;
    color: #1e3f6b;
    border: solid 1px #00beff;
  }

  &--success {
    color: #1e3f6b;
    background: #fff;
    border: solid 1px #00cbae;
  }

  &--danger {
    background: #fef2f7;
    color: #1e3f6b;
    border: solid 1px #f9587a;

    &::placeholder {
      color: #8fb0d4;
    }

    &:focus {
      border: solid 1px #f9587a;
      box-shadow: 0 0 0 1px #f9587a;
    }

    &:hover {
      border-color: #f9587a;
    }
  }
}

.PasswordInput {
  position: relative;
  width: 100%;

  .Input {
    padding: 12px 54px 12px 16px;
  }

  &__Icon {
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 12px;
  }
}
