@import '~bulma/sass/utilities/mixins';

.HeaderTitle {
  overflow: hidden;
  background: #ffffff;
  text-align: left;
  padding: 28px 20px;
  border-bottom: 1px solid #e3ecf5;

  .container {
    display: flex;
  }

  section {
    display: flex;
    justify-content: left;
    align-items: center;

    &:first-child {
      flex-grow: 1;
    }
  }

  h2 {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.2;
    color: #1e3f6b;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include tablet {
      font-size: 20px;
    }
  }

  &__back {
    font-weight: normal;
    display: flex;
    align-items: center;

    a {
      color: #009feb;
      font-size: 12px;
      line-height: 21px;
      margin-left: 5px;
    }

    &:before {
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      margin: 5px;
      border-bottom: solid 2px #009feb;
      border-left: solid 2px #009feb;
      transform: rotate(45deg);
    }
  }

  &--bold {
    h2 {
      font-weight: bold;
    }
  }
}
