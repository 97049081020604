.VerificationForm {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 470px;
  margin: 0 auto;

  &__title {
    color: #1e3f6b;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 16px;
  }

  &__description {
    font-size: 14px;
    color: #1e3f6b;
    margin-bottom: 48px;

    @media only screen and (max-width: 768px) {
      margin-bottom: 40px;
    }
  }

  %form-fields {
    width: 48px;
    height: 48px;
    color: #1e3f6b;
    border-radius: 4px;
    box-shadow: none;
    background-color: #f6f7fa;
    padding: 6px 8px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    outline: none;
    text-transform: uppercase;

    @media only screen and (max-width: 768px) {
      width: 40px;
      height: 40px;
      font-size: 20px;
    }

    @media only screen and (max-width: 320px) {
      width: 32px;
      height: 32px;
      font-size: 16px;
    }
  }

  .control > input {
    @extend %form-fields;
    border: 1px solid #bed2e8;
  }

  .control > input:focus,
  .control > input:active {
    @extend %form-fields;
    border: 1px solid #00beff;
    box-shadow: 0 0 0 1px #009feb;
  }

  &__form {
    &__inputs {
      display: grid;
      grid-template-columns: repeat(6, auto);
      gap: 12px;
      justify-content: center;
      margin-bottom: 12px;

      .field:not(:last-child) {
        margin-bottom: 0;
      }

      @media only screen and (max-width: 768px) {
        gap: 9px;
        margin-bottom: 10px;
      }
    }

    &__submit {
      margin-top: auto;
      padding: 20px 40px;
      height: 64px;
      font-weight: bold;
      width: 100%;
      display: block;
    }

    .field {
      margin-bottom: 0;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }
  }

  &__resend {
    font-size: 14px;
    color: #1e3f6b;
    margin: 24px 0;

    &__link {
      font-weight: bold;
      color: #009feb;
      margin-left: 3px;
      cursor: pointer;
    }

    @media only screen and (max-width: 768px) {
      margin-top: 10px;
    }
  }

  &__errorMessage {
    color: #f9587a;
    font-size: 12px;
  }
}
