.fb-button {
  background-color: #0056b3;
  border: solid 1px #bed2e8;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  height: 48px;

  &:hover,
  &:focus,
  &:active {
    box-shadow: none !important;
    border: solid 1px #bed2e8;
    color: #ffffff;
  }

  img.Icon {
    margin-right: 16px;
  }
}

.facebook-error {
  padding: 10px;
  border-radius: 4px;
  border: solid 1px #f9587a;
  color: #f9587a;
}
