@import '~bulma/sass/utilities/mixins';

.RegistrationForm__registered {
  .modal-card {
    width: auto;
    margin: 16px;
    max-width: 440px;

    @include mobile {
      position: absolute;
    }
  }

  .modal-card-head,
  .modal-card-foot {
    background: #fff;
    padding: 16px;
  }

  .modal-card-foot {
    display: flex;
    justify-content: flex-end;

    .Button {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .Button {
    padding: 12px 24px;
    font-weight: bold;
    font-size: 12px;
    justify-content: center;
    margin-bottom: 0;
  }

  &__title {
    flex-grow: 1;
    font-weight: bold;
    font-size: 16px;
  }

  &__description {
    font-size: 14px;
  }

  &__hyperlink {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
}
