.SoftUpdatePage {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  h3 {
    color: #1e3f6b;
    font-size: 1.5em;
    font-weight: bold;
    margin: 1.5em 0;
  }

  p {
    text-align: center;
    max-width: 300px;
    margin-bottom: 2.5em;
    margin-top: 2em;
  }

  a.Button {
    background: #009feb;
    color: #fff;
    font-weight: bold;
    padding: 14px;
    width: 200px;
    text-align: center;
    justify-content: center;
  }
}
