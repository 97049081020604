.VerificationPage {
  max-width: 100vw;

  &__wrapper {
    margin: 16px;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding: 48px;
    min-height: calc(100vh - 74px - 32px - 96px);
    display: flex;
    align-items: stretch;

    @media only screen and (max-width: 768px) {
      padding: 24px;
      box-shadow: none;
      margin: 0;
    }
  }

  .container {
    width: 100%;
  }
}
