.maintenance {
  margin-top: 120px;
}

.maintenance__illust {
  text-align: center;
  height: 120px;
}

.maintenance__illust > svg {
  width: 120px;
  height: 120px;
}

.maintenance__title {
  margin-top: 16px;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #1e3f6b;
}

.maintenance__text {
  max-width: 343px;
  margin-top: 8px;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #1e3f6b;
}

.maintenance__logo {
  display: block;
  text-align: center;
  margin-top: 16px;
}

.maintenance__logo > svg {
  width: 97px;
  height: 24px;
}

@media all and (min-width: 640px) {
  .maintenance__illust {
    text-align: center;
    height: 160px;
  }

  .maintenance__illust.with-ie {
    text-align: center;
    height: 110px;
  }

  .maintenance__illust > svg {
    width: 160px;
    height: 160px;
  }

  .maintenance__title {
    margin-top: 24px;
    font-size: 24px;
  }

  .maintenance__text {
    max-width: 451px;
  }

  .maintenance__logo {
    margin-top: 24px;
  }

  .maintenance__logo > svg {
    width: 129px;
    height: 32px;
  }
}
