.container {
  &.is-compact {
    @media only screen and (min-width: 768px) {
      max-width: 960px;
    }

    @media only screen and (min-width: 1088px) {
      max-width: 960px;
    }

    @media only screen and (min-width: 1280px) {
      max-width: 1080px;
    }

    @media only screen and (min-width: 1472px) {
      max-width: 1200px;
    }
  }
}
