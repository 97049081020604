.ErrorDialog {
  max-width: 75%;
  margin: auto;
  background: #fff;
  padding: 24px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
  display: inline-block;
  border-radius: 8px;
  text-align: center;

  &__title {
    margin-top: 0;
    margin-bottom: 24px;
    color: #0c2846;
    line-height: 1.5;
    font-size: 22px;
  }

  &__content {
    font-size: 16px;
    line-height: 1.43;
    color: #8fb0d4;
  }

  &__footer {
    margin-top: 24px;

    .Button {
      cursor: pointer;
      margin: auto;
      font-size: 16px;
      font-weight: bold;
    }
  }
}
