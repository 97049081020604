/* Customize the label (the container) */
label.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 400 !important;
}

/* Hide the browser's default checkbox */
label.checkbox-container input[type='checkbox'] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
label.checkbox-container > span.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 21px;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #bed2e8;
}

/* On mouse-over, add a grey background color */
label.checkbox-container:hover input[type='checkbox'] ~ span.checkmark {
  background-color: #f6f7fa;
}

/* When the checkbox is checked, add a blue background */
label.checkbox-container input[type='checkbox']:checked ~ span.checkmark {
  background-color: #00beff;
  border-radius: 4px;
  border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
span.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
label.checkbox-container input[type='checkbox']:checked ~ span.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
label.checkbox-container span.checkmark:after {
  left: 8px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
