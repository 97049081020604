.validation-item {
  &__title {
    color: #6b8fbc;
    font-weight: 600;
    line-height: 1.5;
  }

  &__status {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 30px;
  }

  .Icon {
    backface-visibility: visible;
    animation: bounceIn;
    animation-duration: 0.5s;
  }

  &__description {
    font-size: 14px;
    margin-bottom: 0;
    color: #6b8fbc;
    line-height: 1.5;
    text-align: center;
  }

  @keyframes bounceIn {
    from,
    20%,
    40%,
    60%,
    80%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
      transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
      transform: scale3d(0.9, 0.9, 0.9);
    }

    60% {
      opacity: 1;
      transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
      transform: scale3d(0.97, 0.97, 0.97);
    }

    to {
      opacity: 1;
      transform: scale3d(1, 1, 1);
    }
  }
}
