.validation-list {
  padding: 8px 16px 16px;

  &__title {
    color: #6b8fbc;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 8px;
  }

  &__items {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
  }
}
