.VerificationSuccess {
  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 470px;
    margin: 0 auto;
  }

  &__title {
    color: #1e3f6b;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 16px;
  }

  &__description {
    font-size: 14px;
    color: #1e3f6b;
    margin-bottom: 24px;
  }

  &__img {
    width: 220px;
    margin-bottom: 24px;

    @media only screen and (max-width: 768px) {
      width: 200px;
    }
  }

  &__redirect {
    font-size: 14px;
    color: #1e3f6b;
    margin-bottom: 48px;
  }

  &__link {
    margin-top: auto;
    width: 100%;
    display: block;

    button {
      padding: 20px 40px;
      height: 64px;
      font-weight: bold;
      width: 100%;
    }
  }
}
