.NotFoundPage {
  height: 100vh;
  display: flex;
  flex-direction: column;
  color: #00357b;

  &__body {
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding: 40px 0;
    text-align: center;
    max-width: 448px;
    margin: auto;
  }

  .footer {
    min-height: auto;
  }
}

.NotFoundContent {
  padding: 40px;
  line-height: 1.5;
  color: #1e3f6b;

  &__Image {
    display: block;
    width: 70%;
    max-width: 335px;
    margin: auto;
  }

  &__Message {
    margin-top: 32px;
    color: #00357b;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.5;
  }

  &__Heading {
    font-size: 16px;
    font-weight: 700;
    margin-top: 24px;
  }

  &__Description {
    font-size: 14px;
  }

  &__ButtonContainer {
    margin-top: 40px;

    .Button {
      color: #fff;
      line-height: 1.7;
      cursor: pointer;
      padding: 11px 24px;
      font-weight: bold;
      justify-content: center;
      width: 100%;
      border: 1px solid #009feb;
      background-color: #009feb;
      box-shadow: 0 2px 4px 1px rgba(107, 143, 188, 0.2);

      &:hover {
        background-color: #00beff;
      }
    }
  }
}
