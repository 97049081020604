// override bulma variable
$family-sans-serif: 'Open Sans', sans-serif;
$navbar-breakpoint: 0;

$footer-background-color: #0c2846;
$footer-padding: 33px 15px;

$tabs-border-bottom-width: 2px;
$tabs-link-color: #1e3f6b;
$tabs-link-hover-color: #1e3f6b;
$tabs-link-hover-border-bottom-color: #1e3f6b;
$tabs-link-active-color: #1e3f6b;
$tabs-link-active-border-bottom-color: #00beff;
