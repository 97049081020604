.country-selector-label > label {
  color: #1e3f6b;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  text-align: left;
}

.country-selector-field {
  height: 48px;

  .control {
    height: 48px;

    .select {
      height: 48px;

      select {
        width: 140px;
        height: 48px;
        color: #1e3f6b;
        padding-left: 8px;
        padding-right: 24px;
        border-radius: 4px;
        border: 1px solid #bed2e8;
        box-shadow: none;
        font-size: 14px;

        @media screen and (min-width: 375px) {
          width: 180px;
          padding-left: 15px;
          padding-right: 30px;
          font-size: 16px;
        }
      }

      select:focus,
      select:active {
        border: 2px solid #00beff;
      }
    }

    .select::after {
      right: 8px;
      border-color: #8fb0d4 !important;

      @media screen and (min-width: 375px) {
        right: 1.125em;
      }
    }
  }
}
