.LanguageSelector {
  button {
    padding: 0;
    border: none;
    height: 30px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    background-color: transparent;
  }

  button:active,
  button:focus,
  button:hover {
    border: none;
    color: #ffffff;
    box-shadow: none !important;
  }

  img {
    margin-left: 5px;
    height: 14px;
    width: 14px;
  }
}
