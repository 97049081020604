@import '../../themes/variables';
@import '~bulma/sass/utilities/mixins';

.Tooltip {
  $background-color: rgba(#000, 0.6);

  display: inline-block;
  cursor: pointer;
  position: relative;
  z-index: 2;

  &:before,
  &:after {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    bottom: 110%;
    left: 50%;
  }

  // tooltip baloon
  &:before {
    margin-bottom: 5px;
    margin-left: -50px;
    padding: 10px;
    width: auto;
    border-radius: 3px;
    background: $background-color;
    color: #fff;
    content: attr(data-tooltip);
    text-align: center;
    font-size: 13px;
    line-height: 1.2;
    font-weight: normal;

    @include from($tablet) {
      margin-left: -100px;
      width: 200px;
      max-width: 200px;
    }
  }

  // arrow bottom
  // TODO: add arrow top, left, and right
  &:after {
    margin-left: -5px;
    width: 0;
    border-top: 5px solid #000;
    border-top: 5px solid $background-color;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    content: ' ';
  }

  // show content on hover
  &:hover:before,
  &:hover:after {
    visibility: visible;
    opacity: 1;
  }
}
