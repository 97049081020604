@import '~bulma/sass/utilities/initial-variables';
@import './themes/variables';
@import '~bulma/bulma';

html,
body {
  height: 100%;
  background-color: #f6f7fa;
}

body,
button,
input,
select,
textarea {
  font-family: $family-sans-serif;
}
