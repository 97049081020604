$text-color: #1e3f6b;
$white-color: #ffffff;

.ActionLink {
  background-color: $white-color;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 15px;
  padding: 40px;

  .title {
    color: $text-color;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
  }

  .subtitle {
    color: $text-color;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }

  .Contact {
    margin-left: auto;
    margin-right: auto;
    padding: 40px;
    height: 128px;
    border-radius: 4px;
    border: solid 1px #bed2e8;
    font-size: 16px;
    font-weight: 700;
    width: 40%;
  }
}
