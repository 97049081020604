@import '../../themes/variables';
@import '~bulma/sass/utilities/mixins';
@import '~bulma/sass/layout/footer';

.Footer {
  @extend .footer;
  min-height: 96px;
  padding: 33px 20px;

  %footer-text-style {
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
  }

  span.copyright {
    @extend %footer-text-style;
    font-weight: bold;
  }
}

.Footer.mobile {
  .mobile-item {
    vertical-align: middle;
    display: inline-block;
    margin-right: 30px;

    img.Brand {
      height: 24px;
      vertical-align: bottom;
    }

    a.footer-link {
      @extend %footer-text-style;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .brand-container {
    margin-top: 10px;
  }
}

.Footer.desktop {
  .level-right > .level-item {
    margin-left: 20px;
  }

  .level-left > .level-item {
    margin-right: 20px;
  }

  a.level-item {
    @extend %footer-text-style;

    &:hover {
      text-decoration: underline;
    }
  }
}
