.Button {
  transition: 0.5s;
  display: flex;
  border: none;
  padding: 8px;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  border-radius: 4px;
  align-items: center;
  cursor: pointer;

  &--default {
    background: transparent;
    font-weight: bold;
    color: #00beff;
  }

  &--info {
    background: #009feb;
    border: solid 1px #009feb;
    color: #fff;
    box-shadow: 0 2px 4px 1px rgba(107, 143, 188, 0.2);

    &:hover {
      border: solid 1px #00beff;
      background: lighten(#00beff, 5%);
    }
  }

  &--disabled {
    background: #e3ecf5;
    color: #8fb0d4;
    cursor: not-allowed;
  }

  &--success {
    border: solid 1px #00cbae;
    background: #00cbae;
  }

  &--danger {
    border: solid 1px #f9587a;
    background: #f9587a;
  }

  &--idle {
    background: transparent;
    font-weight: bold;
    color: #6b8fbc;
    border: solid 1px #6b8fbc;
  }

  &--text {
    &-info {
      background: transparent;
      color: #00beff;
    }
  }

  &--light {
    font-weight: 700;
    background-color: #fff;
    border: 1px solid #bed2e8;
    color: #1e3f6b;

    &:hover {
      background: darken(#fff, 2%);
    }

    &:disabled {
      background: #e3ecf5;
      color: #8fb0d4;
    }
  }

  &--is-fullwidth {
    width: 100%;
  }
}
