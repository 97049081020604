@import '~bulma/sass/utilities/initial-variables';

.RegistrationForm {
  max-width: 880px;
  padding: 48px 20px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);

  .form-column {
    margin: auto;
    max-width: 480px;
  }

  div.unsupported-message-wrapper {
    margin-bottom: 10px;
  }

  h1 {
    color: #1e3f6b;
    text-align: center;
  }

  a:hover {
    color: #3273dc;
    text-decoration: underline;
  }

  p.agreement {
    margin-top: 20px;
  }

  p.agreement,
  p.login {
    color: #1e3f6b;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
  }

  p.is-danger {
    margin-top: 8px;
    border-radius: 4px;
  }

  div.line-separator {
    width: 100%;
    height: 14px;
    margin: 25px 0 40px 0;
    border-bottom: 1px solid #e3ecf5;
    text-align: center;

    span.text-separator {
      font-size: 14px;
      background-color: white;
      padding: 0 40px;
      color: #6b8fbc;
    }
  }

  a.link {
    font-weight: bold;
    color: #009feb;
  }

  .small > label > span {
    font-size: 12px;
  }

  form {
    @media only screen and (min-width: $tablet) {
      .first-name-field {
        margin-right: -8px;
      }

      .last-name-field {
        margin-left: -8px;
      }

      .first-name-field-ja {
        margin-left: -8px;
      }

      .last-name-field-ja {
        margin-right: -8px;
      }
    }

    @media only screen and (max-width: $tablet) {
      .last-name-field,
      .last-name-field-ja {
        margin-top: -8px;
      }
    }

    label {
      min-height: 24px;
      color: #1e3f6b;
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
      text-align: left;

      span.tag {
        margin-right: 8px;
      }
    }

    .name-fields-error-messages {
      @media only screen and (min-width: $tablet) {
        margin-top: -24px;
      }

      @media only screen and (max-width: $tablet) {
        margin-top: -36px;
      }
    }

    %form-fields {
      height: 48px;
      color: #1e3f6b;
      border-radius: 4px;
      box-shadow: none;
      background-color: #f6f7fa;
      padding: 12px 16px;
    }

    ::placeholder {
      color: #8fb0d4;
      opacity: 1;
    }

    .control > input,
    .control > .select > select {
      @extend %form-fields;
      border: 1px solid #bed2e8;
    }

    .control > .PasswordInput > input {
      @extend %form-fields;
      padding: 12px 54px 12px 16px;
    }

    .control > input:focus,
    .control > input:active,
    .control > .select > select:focus,
    .control > .select > select:active,
    .control > .PasswordInput > input:focus,
    .control > .PasswordInput > input:active {
      @extend %form-fields;
      border: 1px solid #00beff;
      box-shadow: 0 0 0 1px #009feb;
    }

    .control > .select {
      height: 48px;
    }

    .control > .select::after {
      border-color: #8fb0d4 !important;
    }

    .control > button {
      height: 64px;
      color: #ffffff;
      background-color: #00beff;
    }

    .control > label > input {
      margin-right: 5px;
    }

    .control
      > label
      > input[name='membership-checkbox']
      + .membership-code-label {
      margin-right: 15px;
    }

    label.checkbox-container {
      margin-right: 15px;
    }

    label.checkbox-container > span.Tooltip > img.Icon {
      vertical-align: text-bottom;
      height: 20px;
      width: 20px;
    }

    .PasswordInput {
      &__Icon {
        top: 11px;
        right: 15px;
      }
    }

    button.submit-btn {
      font-weight: bold;
      background-color: #009feb;

      @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) {
          -webkit-transform: translate3d(0, 0, 0);
        }
      }
    }

    button.submit-btn:hover {
      background-color: #00beff;
    }

    .tag {
      width: 80px;
      height: 24px;
      margin-right: 5px;
      border-radius: 2px;
      vertical-align: middle;
    }

    .tag > img.Icon {
      height: 20px;
    }

    .required-tag {
      background-color: #f9587a;
      color: #ffffff;
    }

    .optional-tag {
      background-color: #e3ecf5;
      color: #1e3f6b;
    }

    .valid-tag {
      background-color: #00cbae;
      color: #ffffff;
    }

    .is-reversed {
      display: flex;

      @media only screen and (min-width: $tablet) {
        flex-direction: row-reverse;
      }

      @media only screen and (max-width: $tablet) {
        flex-direction: column-reverse;
      }
    }
  }

  .mt-0 {
    margin-top: 0;
  }
}

.field {
  &.is-danger {
    .control > .PasswordInput > input,
    .control > input,
    .control > .select > select {
      border: 1px solid #f9587a;
      background-color: #fef2f7;

      &:focus,
      &:active {
        border: solid 1px #f9587a;
        box-shadow: 0 0 0 1px #f9587a;
        background-color: #fef2f7;
      }
    }
  }
}
