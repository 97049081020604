@import '../../themes/variables';
@import '~bulma/sass/utilities/mixins';

.InvalidPage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &__body {
    background: #f6f7fa;
    flex-grow: 1;
    padding: 20px;

    @include desktop {
      padding: 40px 10px;
    }
  }

  &__content {
    @include mobile {
      margin: -5px -20px -30px -20px;
    }
  }
}
