.Overlay {
  transition: 0.5s;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(#000, 0.5);
  z-index: 9999;
  display: none;

  &--show {
    display: flex;
  }
}
