.Icon {
  width: 24px;
  height: 24px;

  &--left {
    left: 0;
  }

  &--right {
    right: 0;
  }

  &--sm {
    width: 16px;
    height: 16px;
  }

  &--md {
    width: 24px;
    height: 24px;
  }

  &--lg {
    width: 32px;
    height: 32px;
  }
}
